
import { myworks, menu } from '../data'
import ProjectPage from './ProjectPage'

export default function Projects() {
  return (
    <div id="myworks" className=" min-h-min text-center pb-10 relative">
      <h1 className=" my-title">{menu[2].name}</h1>
      <div className=' mb-5 md:mb-14 grid md:grid-cols-3 gap-5 px-5'>
        {
          myworks.map((project, index) => (
            // <img key={index} src={project.img} alt="" />    
            <ProjectPage key={index} project={project} />
          ))
        }
      </div>
    </div>
  )
}
