import { myself, menu } from '../data'
import Myphoto from '../assets/img/me.png'

export default function About() {
  return (
    <div id="about" className="max-h-max bg-white text-center p-10">
      <h1 className=" my-title ">{menu[1].name}</h1>
      <div className="block md:hidden">
        <img src={Myphoto} className=' mx-auto rounded-full w-60 h-60 my-5 lg:w-96 lg:h-96 overflow-hidden ' alt="" />
      </div>
      <div className=" lg:w-[60%] md:w-[80%] md:px-10 mx-auto md:text-xl text-secondary text-justify py-8 ">
        {
          myself.bio.map((text, index) => {
            return <div key={index} className=" mb-10 font-extralight tracking-wider leading-8 text-primary ">{text}</div>
          })
        }
      </div>
    </div>
  )
}
