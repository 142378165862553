import { Link } from 'react-scroll'
import { menu } from '../data'
import resume from '../assets/files/JubalThang-2022.pdf'

export default function MobileMenu({ showMenu, hideMenu }) {
    return (
        <>
            <div className={`fixed right-0 top-0 ${showMenu ? 'w-0' : 'w-[90%]'} h-full bg-white ease-in-out duration-500`} >
                <div className=' flex justify-start cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => hideMenu(!showMenu)} className="h-9 w-9 m-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <div className=' p-10 max-w-max ' onClick={() => hideMenu(!showMenu)}>
                    <ol className=' grid gap-9 text-2xl '>
                        {
                            menu.map((menu, index) => {
                                return (
                                    <li key={index} className='text-secondary  cursor-pointer uppercase'>
                                        <Link
                                            onClick={() => hideMenu(!showMenu)}
                                            to={menu.link}
                                            spy={true}
                                            activeClass='active'
                                            smooth={true}
                                            duration={500}
                                            offset={-70}
                                            className=" hover:text-red-400 cursor-pointer max-w-min menu-btn "> {menu.name}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                        <li className='text-secondary'><a href={resume} target='_blank' rel='noopener noreferrer' download className='hover:text-primary cursor-pointer'>RESUME</a></li>
                    </ol>
                </div>
            </div>
        </>
    )
}
