// import { useEffect } from "react"

import { useEffect, useState } from "react"
import Modal from "./modal/Modal"

export default function ProjectPage({ project }) {
    const [open, setOpen] = useState(false)
    // disable the background scroll
    useEffect(() => {
        open ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible'
    }, [open])

    function showHideModal() {
        setOpen(!open)
    }
    return (
        <div className=" flex flex-col items-center" >
            <h1 className=" uppercase lg:text-xl font-bold md:font-medium p-2 md:mt-10 md:mb-5 ">{project.name}</h1>
            <div onClick={() => showHideModal()}>
                <img className=" w-96 rounded-md shadow-md shadow-primary cursor-pointer mx-auto hover:scale-110  duration-300" src={project.image} alt="" />
            </div>
            {
                open &&
                // <div className={`absolute top-0 left-0 w-full h-full backdrop-blur-sm bg-opacity-25 bg-white`}>
                //     <button onClick={() => setOpen(false)}>Close</button>
                // </div>
                <Modal project={project} showHideModal={showHideModal} show={open}/>
            }

        </div>
    )
}


