import { useEffect, useState } from "react"
import { MenuIcon } from '../assets/icons'
import MobileMenu from "./MobileMenu"
import { menu } from '../data'
import resume from '../assets/files/JubalThang-2022.pdf'
import { Link } from "react-scroll"

export default function Topbar() {

    const [bg, setBg] = useState(false)
    const [showMenu, setShowMenu] = useState(true)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            return window.scrollY > 10 ? setBg(true) : setBg(false)
        })
    })

    return (
        <div className={` ${bg ? 'h-20 bg-white shadow-sm ' : 'h-24'} top-0 md:px-16 px-4 sticky flex items-center justify-center transition-all duration-300 z-10`}>
            {/* // <header className={`${bg ? 'bg-white h-20' : 'h-24'} sticky top-0 flex px-8`}> */}
            <div className=' container flex items-center min-h-max justify-between '>
                <h1 className=' font-black text-primary text-3xl '>JUBAL.</h1>
            </div>
            {/* small screen */}
            <div className=' lg:hidden cursor-pointer ' onClick={() => setShowMenu(!showMenu)}>
                <MenuIcon />
            </div>
            {/* large screen */}
            <div className="hidden lg:flex items-center justify-center min-w-max ">
                <ol className='inline-flex text-gray-400 text-xl space-x-8'>
                    {
                        menu.map((menu, index) => {
                            return (
                                <li key={index}
                                    className=' cursor-pointer '>
                                    <Link
                                        to={menu.link}
                                        spy={true}
                                        activeClass='active'
                                        smooth={true}
                                        duration={300}
                                        offset={-70}
                                        className=" hover:text-primary cursor-pointer "> {menu.name}
                                    </Link>
                                </li>
                            )
                        })
                    }
                <li><a href={resume} target='_blank' rel='noopener noreferrer' download className='hover:text-primary cursor-pointer'>resume</a></li>
                </ol>
            </div>

            <MobileMenu hideMenu={setShowMenu} showMenu={showMenu} />
        </div>
    )
}


