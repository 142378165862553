
export default function Slider({value}) {

    // const [style, setStyle] = useState()

    // setTimeout(() => {
    //     const newStyle = {
    //         opacity: 1,
    //         width: `${value}%`,
    //     }

    //     setStyle(newStyle)
    // }, 1000)
    return (
        <div className='py-2'>
            <div className=" w-full h-2 bg-[#bbbbbb] rounded relative flex items-center mb-4">
                <div className={` w-0 bg-primary h-full rounded-l-full duration-300`} style={{width: `${value}%`}}></div>
            </div>
        </div>
    )
} 