import { menu } from '../data'
import { BsLinkedin, BsGithub } from 'react-icons/bs'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'

export default function Contact() {

  const form = useRef()
  const refRecap = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    if (refRecap.current.getValue()) {
      emailjs.sendForm(process.env.REACT_APP_EMAIJJS_SERVICE_ID, process.env.REACT_APP_EMAIJJS_SERVICE_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          result.text === 'OK' && toast.success("Email has been sent!")
        }, (err) => {
          toast.error("Something went wrong try again!", err)
          console.log('something wrong!')
        })
    }
    form.current.reset()
  }

  const handleOnChange = (e) => {

  }
  return (
    <div id="contact" className=' h-min-max mb-48 '>
      <h1 className=" my-title ">{menu[4].name}</h1>
      <div className=" flex justify-center items-start w-full h-full ">
        <div className=" grid md:grid-cols-2 w-10/12 mx-auto space-x-20 ">
          <form ref={form} onSubmit={sendEmail} className=" w-full space-y-7 mt-8">
            <input type="text" name="from_name" placeholder="Your Name*" className="bg-background border-b w-full pb-2 border-secondary text-secondary focus:outline-none p-2 " required />
            <input type="email" name="from_name" placeholder="Your Email*" className="bg-background border-b w-full pb-2 border-secondary text-secondary focus:outline-none p-2 " required />
            <textarea type="text" placeholder="Your Message*" name="message" className="bg-background border h-28 py-2 px-4 w-full pb-2 border-secondary text-secondary focus:outline-none" required />
            <ReCAPTCHA
              ref={refRecap}
              sitekey={`${process.env.REACT_APP_RECAPTCHA_RESTRICT_SITE_KEY}`}
              onChange={handleOnChange}
            />
            <input type="submit" value="Send Message" className="bg-primary text-white py-2 px-4 w-[1/2] font-light cursor-pointer hover:bg-secondary" />
          </form>

          <div className=" flex-col flex justify-center items-start mt-20 md:mt-0 space-y-10">
            <a href="https://www.linkedin.com/in/jubalthang/" target="_blank" rel="noopener noreferrer">
              <div className="flex space-x-6 items-center ">
                <BsLinkedin className=" w-12 h-auto text-primary" />
                <h1 className='text-primary font-bold text-2xl'>Linkedin</h1>
              </div>
            </a>
            <a href="https://github.com/JubalThang" target="_blank" rel="noopener noreferrer">
              <div className=" flex space-x-6 items-center ">
                <BsGithub className=" w-12 h-auto text-primary" />
                <h1 className='text-primary font-bold text-2xl'>Github</h1>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}
