import { AiOutlineCloseCircle } from 'react-icons/ai'

export default function Modal({ project, showHideModal }) {

    function hideModal(e) {
        if (e.target.id === 'container') showHideModal()
        // console.log(e.target.id)
    }
    return (
        <div id='container' className=" fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center z-50" onClick={hideModal}>
            <div className='mx-4 md:w-1/2 min-h-min bg-white rounded-md'>
                <div className=" flex justify-between border-b-2 max-h-[75%] overflow-scroll">
                    <div></div>
                    <h1 className=' text-xl uppercase p-5 md:my-title'>{project.name}</h1>
                    <div className="p-5">
                        <button onClick={showHideModal} className=' text-2xl rounded-full md:text-4xl text-primary'><AiOutlineCloseCircle /></button>
                    </div>
                </div>
                <div className='lg:flex items-start p-5 '>
                    <div className=' md:p-5 md:w-1/2 h-auto'>
                        <img src={project.image} alt="Tiny Project" className='rounded-md' />
                    </div>
                    <div className='flex-1 text-left md:pt-5 flex-shrink-0'>
                        <h1 className=' font-medium pt-4 md:pt-0 text-primary md:text-xl'>Descriptions:</h1>
                        <p className=' text-secondary py-3 text-sm md:text-base'>
                            {project.description}
                        </p>
                        <div className='flex py-4 space-x-4'>
                            {project.url && <a href={project.url} target='_blank' rel='noopener noreferrer' className='text-primary underline'>demo site:</a>}
                            <a href={project.source} target='_blank' rel='noopener noreferrer' className='text-primary underline'>source code:</a>
                        </div>
                        <h1 className=' font-medium  text-primary md:text-xl'>Frontend:</h1>
                        <div className="flex">
                            {
                                project.frontend.map((item, index) =>
                                (
                                    <span className='pr-5 pt-4' key={index}>
                                        <i className='text-4xl'>{item}</i>
                                    </span>
                                )
                                )
                            }
                        </div>
                        {
                            project.backend.length > 0 && <>
                                <h1 className=' font-medium  text-primary mt-4 md:text-xl'>Backend:</h1>
                                <div className="flex">
                                    {
                                        project.backend.map((item, index) =>
                                        (
                                            <span className='pr-5 pt-4' key={index}>
                                                <i className='text-4xl'>{item}</i>
                                            </span>
                                        )
                                        )
                                    }
                                </div></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
