import UYC from "./assets/projects/UYCconf.png";
import HC from "./assets/projects/HCConf.png";
import Cakes from "./assets/projects/cakes.png";
import TinyBlog from "./assets/projects/TinyBlog.png";
import Et from "./assets/projects/Et.png";
import toystory from "./assets/projects/toystory.png";
import xml from "./assets/projects/xml.png";
import MWC from "./assets/projects/mwc.jpg";

// logo import
import heroku from "./assets/Logos/heroku.png";
import {
  IoLogoReact,
  IoLogoHtml5,
  IoLogoJavascript,
  IoLogoAppleAppstore,
} from "react-icons/io5";
import {
  SiRubyonrails,
  SiHeroku,
  SiPython,
  SiFirebase,
  SiTailwindcss,
  SiMaterialui,
  SiNetlify,
} from "react-icons/si";
import { FaSwift } from "react-icons/fa";
import { VscJson } from "react-icons/vsc";

const javaScriptLogo = <IoLogoJavascript className="text-yellow-300" />;
const htmlLogo = <IoLogoHtml5 className=" text-[#F06729]" />;
const reactlogo = <IoLogoReact className="text-[#4EDDFF]" />;
const tailwindLogo = <SiTailwindcss className="text-[#41B6D6]" />;
const materialUi = <SiMaterialui className="text-[#2E80CF]" />;
const swiftLogo = <FaSwift className="text-[#F33B27]" />;
const fireBaseLogo = <SiFirebase className="text-[#F8CB2A]" />;
const railsLogo = <SiRubyonrails className="text-[#CD0000]" />;
const netLifyLogo = <SiNetlify className="text-[#65b997]" />;

export const menu = [
  {
    name: "home",
    link: "home",
  },
  {
    name: "about me",
    link: "about",
  },
  {
    name: "my projects",
    link: "myworks",
  },
  {
    name: "skills",
    link: "skills",
    skills: [
      {
        name: "Design",
        skill: "85",
      },
      {
        name: "HTML & CSS",
        skill: "85",
      },
      {
        name: "React & Nodejs",
        skill: "80",
      },
      {
        name: "Firebase & AWS",
        skill: "70",
      },
      {
        name: "Heroku",
        skill: "65",
      },
      {
        name: "UIKit & SwiftUI",
        skill: "80",
      },
      {
        name: "Java, Python & Ruby",
        skill: "60",
      },
    ],
  },
  {
    name: "contact me",
    link: "contact",
  },
];

export const myself = {
  name: "jubal thang",
  moto: "Develop Applications with Love by Grace",
  bio: [
    `I was born and raised in Myanmar(Burma). I didn't know how to use the computer until I graduated from high school. My very first programming language was TurboC++. My first project, played "Happy Birthday" by internal BIOS (beep) speaker, was written on a paper.`,
    "After settling down in US, I continue pursue my dream to become a Software Developer. I went to Flatiron Coding Bootcamp studied about Full-stack Web developing. After graduated in 2022, I am now working as a freelance full-stack developer.",
    `I have experiences in creating iOS Apps with native UIKit or SwiftUI and Web Applications in React with Ruby on Rails. If you have an idea to create an application but don’t know where to start, contact me!`,
  ],
};

export const myworks = [
    {
        name: "MWC Website",
        image: MWC,
        url: "https://mwctulsa.church",
        description:
          "Myanmr Worship Center Church Website.",
        frontend: [reactlogo, tailwindLogo, javaScriptLogo],
        backend: [netLifyLogo],
        hosting: netLifyLogo,
      },
    {
        name: "FGAIM HomeComing",
        image: HC,
        source: "https://github.com/Vaphual/unitedyouthconference",
        description:
          "Home Coming Conference App for Full Gospel Assembly International Church. Through the app attendee can do sign up, check scedules and speakers, churches info, and online giving. Backend data handled by Google Firebase API realtime updates.",
        frontend: [swiftLogo],
        backend: [fireBaseLogo],
        hosting: <IoLogoAppleAppstore />,
      },
  {
    name: "UYC Conference",
    image: UYC,
    source: "https://github.com/Vaphual/unitedyouthconference",
    description:
      "My very first iOS mobile application developed Xcode with UiKit. Everything about the conference information, timetable, speakers, location, emergency contacts, are on the app. Used Firebase Realtime datatabase for backend hosting and published on Appstore.",
    frontend: [swiftLogo],
    backend: [fireBaseLogo],
    hosting: <IoLogoAppleAppstore />,
  },
  {
    name: "Cakes Shop",
    image: Cakes,
    source: "https://github.com/JubalThang/phrase_2_final_project",
    description:
      "Coding bootcamp project for cakes shop. Browsing the cakes from the shop produces and add or remove the favorites in the list. ReactJS with Semetic ReactUI and handle the backend database with Json-server.",
    frontend: [reactlogo],
    backend: [<VscJson />],
    hosting: <IoLogoAppleAppstore />,
  },
  {
    name: "Tiny Blog",
    image: TinyBlog,
    url: "https://serene-beyond-03472.herokuapp.com/",
    source: "https://github.com/JubalThang/phase4-project-backend-only",
    description:
      "This is a fullstack blogging website build with Ruby on Rails. Everyone can read all blog posts but only blog members can comment and like the posts. User can sign up and the database build with PostgreSQL and host on Heroku. ",
    frontend: [reactlogo, tailwindLogo, javaScriptLogo],
    backend: [railsLogo],
    hosting: <SiHeroku />,
  },
  {
    name: "Toy Story",
    image: toystory,
    source: "https://github.com/JubalThang/phase-1-practice-toy-tale",
    description:
      "This is one of my school projects. Allow to delete or add a new cartoon character and list all the characters from database. JSON Server for backend database handling. HTML, Vanilla JS and CSS with Material UI for frontend developing.",
    frontend: [htmlLogo, javaScriptLogo, materialUi],
    backend: [<VscJson />],
    hosting: [],
  },
  {
    name: "WebScreap",
    image: xml,
    source: "https://github.com/JubalThang/xml_converter",
    description:
      "This is a Python applications for webscrapping. This is my personal project to convert website to xml. This app will take the url link of Online Bible and fetch the Website contents and converts the chapters and verses to Zefinial xml format. Regular Expression is used to filter the HTML tags and classes.",
    frontend: [htmlLogo, <SiPython />],
    backend: [],
    hosting: [],
  },
  {
    name: "EqManager",
    image: Et,
    url: "https://intense-lowlands-81198.herokuapp.com/",
    source: "https://github.com/JubalThang/phase-5-final-project",
    description:
      "Another Ruby on Rails application to manage equipment. This app is design for small churches or organizations. Account admin can create departments and add/modify equipment. All members can access the database.",
    frontend: [htmlLogo, reactlogo, tailwindLogo],
    backend: [railsLogo],
    hosting: heroku,
  },
];
