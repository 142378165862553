import Myphoto from '../assets/img/me.png'
import { myself } from '../data'
import resume from '../assets/files/JubalThang-2022.pdf'
import { AiOutlineDownload } from 'react-icons/ai'

export default function Home() {
  return (
    <section id="home" className=" md:min-h-max pb-10 -mt-10 ">
      <div className=" flex justify-evenly items-center p-16 ">
        <div className=' hidden md:block mt-10'>
          <div className=' flex justify-center '>
            <img src={Myphoto} className=' rounded-full w-80 h-80 lg:w-96 lg:h-96 overflow-hidden ' alt="" />
          </div>
        </div>
        <div className=" max-w-max text-center ">
          <div className="px-4 flex flex-col items-center">
            <h4 className=' text-lg  text-secondary uppercase'> Hello, my name is</h4>
            <h1 className=' text-primary text-4xl min-w-max lg:text-6xl font-bold py-[22px] uppercase'>{myself.name}</h1>
            <p className=' text-secondary min-w-max capitalize'>{myself.moto}</p>
            {/* <button className=' primary-btn mt-[22px]'>Resume
              
            </button> */}
            <a href={resume} target='_blank' rel='noopener noreferrer' download className=' primary-btn mt-[30px] flex justify-center items-center text-white max-w-min'>RESUME 
            <AiOutlineDownload className='ml-10 w-7 h-7' /> </a>
          </div>
        </div>
      </div>
    </section>
  )
}
