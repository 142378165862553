import { menu } from '../data'
import Slider from './Slider/Slider'

export default function Skills() {
  
  return (
    <div id="skills" className='min-h-max bg-white w-full '>
      <h1 className=" my-title">{menu[3].name}</h1>
      <div className="w-full p-10 md:p-20 text-left">
        {
          menu[3].skills.map((skill, index) => (
            <div key={index} className='w-full md:w-[60%] text-center mx-auto'>
              <div className="flex justify-between items-center">
                <h1 className=' font-bold text-xl text-left text-primary min-w-min pb-2 '>{skill.name}</h1>
                <h1 className='font-bold text-xl text-left text-primary min-w-min'>{skill.skill}%</h1>
              </div>
              {/* <input type='range' min="0" max="100" defaultValue={skill.skill} className=" input-range mb-8 "></input> */}
              <Slider value={skill.skill} />
            </div>
          ))
        }

      </div>
    </div>
  )
}
